// import Swiper JS
import Swiper, { Autoplay, EffectFade, Navigation, Controller, Pagination } from 'swiper';
// import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';

window.addEventListener('DOMContentLoaded', () => {
    // homepage slider
    const homepageHero = new Swiper('.hero-slider', {
        modules: [Autoplay, EffectFade],
        effect: 'fade',
        speed: 800,
        centeredSlides: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        loop: true,
    });

    $('.banner-slider').each(function () {
        const bannersWrapper = $(this);
        const productsCarousel = bannersWrapper.find('.products-banner-container');
        const nextEl = bannersWrapper.find('.swiper-button-next');
        const prevEl = bannersWrapper.find('.swiper-button-prev');
        const bulletsEl = bannersWrapper.find('.homepage-banner-pagination');
        let loop = false;
        let autoplay = false;

        if(productsCarousel.hasClass('section-one-container')){
            loop = true;
            autoplay = {
                delay: 2500
            }
        }

        const homepageBannerSliderItems = new Swiper(productsCarousel[0], {
            modules: [Autoplay, Navigation, Controller, Pagination, EffectFade],
            centeredSlides: true,
            effect: 'fade',
            fadeEffect: { crossFade: true },
            loop: loop,
            autoplay: autoplay,
            infinite: true,
            pagination: {
                el: bulletsEl[0],
                type: 'bullets',
            },
            navigation: {
                nextEl: null,
                prevEl: null,
            },
            breakpoints: {
                768: {
                    navigation: {
                        nextEl: nextEl[0],
                        prevEl: prevEl[0],
                    },
                    loop: loop,
                    autoplay: autoplay,
                    pagination: {
                        el: null,
                    },
                },
            },
        });
    });

    const aboutSuppliers = new Swiper('.about-suppliers', {
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        spaceBetween: 25,
        loop: false,
        navigation: {
            nextEl: '.supplier-button-next',
            prevEl: '.supplier-button-prev',
        },
        pagination: {
            el: '.swiper-pag',
            type: 'bullets',
            clickable: true,
        },
        breakpoints: {
            576: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 25,
            },
            1440: {
                slidesPerView: 4,
                spaceBetween: 50,
            },
        },
    });

    const productsSlider = new Swiper('.related-slider', {
        modules: [Navigation, Pagination],
        slidesPerView: 2,
        spaceBetween: 3,
        pagination: {
            el: '.product-slider-pagination.related',
            type: 'bullets',
            clickable: true,
        },
        breakpoints: {
            768: {
                slidesPerView: 3,
                navigation: {
                    nextEl: '.product-slider-next.related',
                    prevEl: '.product-slider-prev.related',
                },
                pagination: {
                    el: null,
                },
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 30,
                navigation: {
                    nextEl: '.product-slider-next.related',
                    prevEl: '.product-slider-prev.related',
                },
            },
        },
    });

    const combineSlider = new Swiper('.combine-slider', {
        modules: [Navigation, Pagination],
        slidesPerView: 2,
        spaceBetween: 30,
        pagination: {
            el: '.product-slider-pagination.combine',
            type: 'bullets',
            clickable: true,
        },
        breakpoints: {
            768: {
                slidesPerView: 3,
                navigation: {
                    nextEl: '.product-slider-next.combine',
                    prevEl: '.product-slider-prev.combine',
                },
                pagination: {
                    el: null,
                },
            },
            1024: {
                slidesPerView: 4,
                navigation: {
                    nextEl: '.product-slider-next.related',
                    prevEl: '.product-slider-prev.related',
                },
            },
        },
    });

    const relativePosts = new Swiper('.relative-posts', {
        modules: [Pagination],
        slidesPerView: 1,
        spaceBetween: 25,
        loop: true,
        pagination: {
            el: '.relative-pagination',
            type: 'bullets',
            clickable: true,
        },
        breakpoints: {
            581: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            1141: {
                slidesPerView: 4,
                spaceBetween: 20,
            },
        },
    });

    const homePosts = new Swiper('.home-posts', {
        modules: [Pagination],
        slidesPerView: 1,
        spaceBetween: 25,
        loop: false,
        pagination: {
            el: '.home-pagination',
            type: 'bullets',
            clickable: true,
        },
        breakpoints: {
            581: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            1141: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
        },
    });

    const kitchenProducts = new Swiper('.kitchen-grid', {
        modules: [Autoplay, Pagination],
        slidesPerView: 2,
        spaceBetween: 3,
        autoplay: {
            delay: 3500,
        },
        loop: true,
        pagination: {
            el: '.kitchen-banner-pagination',
            type: 'bullets',
            clickable: true,
        },
        breakpoints: {
            768: {
                slidesPerView: 3,
                spaceBetween: 3,
            },
            992: {
                slidesPerView: 4,
                spaceBetween: 10,
            },
        },
    });

    const personalCareProducts = new Swiper('.personal-care-grid', {
        modules: [Autoplay, Pagination],
        slidesPerGroup: 2,
        slidesPerView: 2,
        spaceBetween: 3,
        autoplay: {
            delay: 3500,
        },
        loop: true,
        pagination: {
            el: '.personal-care-banner-pagination',
            type: 'bullets',
            clickable: true,
        },
        breakpoints: {
            768: {
                slidesPerGroup: 3,
                slidesPerView: 3,
                spaceBetween: 3,
            },
            992: {
                slidesPerGroup: 4,
                slidesPerView: 4,
                spaceBetween: 10,
            },
        },
    });

    const petProducts = new Swiper('.pet-grid', {
        modules: [Autoplay, Pagination],
        slidesPerGroup: 2,
        slidesPerView: 2,
        spaceBetween: 3,
        autoplay: {
            delay: 3500,
        },
        loop: true,
        pagination: {
            el: '.pet-banner-pagination',
            type: 'bullets',
            clickable: true,
        },
        breakpoints: {
            768: {
                slidesPerGroup: 3,
                slidesPerView: 3,
                spaceBetween: 3,
            },
            992: {
                slidesPerGroup: 4,
                slidesPerView: 4,
                spaceBetween: 10,
            },
        },
    });

    const cleaningProducts = new Swiper('.cleaning-grid', {
        modules: [Autoplay, Pagination],
        slidesPerGroup: 2,
        slidesPerView: 2,
        spaceBetween: 3,
        autoplay: {
            delay: 3500,
        },
        loop: true,
        pagination: {
            el: '.cleaning-banner-pagination',
            type: 'bullets',
            clickable: true,
        },
        breakpoints: {
            768: {
                slidesPerGroup: 3,
                slidesPerView: 3,
                spaceBetween: 3,
            },
            992: {
                slidesPerGroup: 4,
                slidesPerView: 4,
                spaceBetween: 10,
            },
        },
    });

    // product cards slider
    $('.product-card-slider').each( function(){
        const productCards = new Swiper(this, {
            modules: [Navigation],
            slidesPerView: 1,
            spaceBetween: 5,
            loop: true,
            navigation: {
                nextEl: '.product-card-arrow-next',
                prevEl: '.product-card-arrow-prev',
            },
        });
    })

    setTimeout( function(){
        const $productsWrapper = $('.product-col');
        hintSlider($productsWrapper[0], 0, 500);
        hintSlider($productsWrapper[1], 800, 1300);
    }, 1000)
});

function hintSlider(elementWrapper, start, delay) {
    const sliderWidth = $(elementWrapper).find('.product-card-slider').width() / 2;
    const elementToAnimate = $(elementWrapper).find('.product-card-slider .swiper-wrapper');
    setTimeout(function(){
        elementToAnimate.animate({
            left: '-=' + sliderWidth + 'px',
        }, 600);
    }, start)
    setTimeout(function(){
        elementToAnimate.animate({
            left: '0',
        }, 600);
    }, delay)
}
