window.isTouchDevice = function () {
    return true == ('ontouchstart' in window || (window.DocumentTouch && document instanceof DocumentTouch));
};

window.screen = function () {
    var state = 'click';
    if (isTouchDevice() === true) {
        state = 'touchstart';
    }
    return {
        h: jQuery(window).height(),
        w: jQuery(window).width(),
        scroll: jQuery(window).scrollTop(),
        pointerAction: state,
    };
};

// Functions
const dynamicCSS = (selector, target, property) => {
    let value = target.offsetHeight;
    selector.style.setProperty(property, `${value}px`);
    window.addEventListener('resize', () => {
        value = target.offsetHeight;
        selector.style.setProperty(property, `${value}px`);
    });
};

$(document).ready(function () {
    $('.smoothScroll').on(screen().pointerAction, function (e) {
        e.preventDefault();
        var header = document.getElementsByTagName('header')[0].offsetHeight;
        var hh;
        if (screen().w < 991) {
            hh = $($(this).attr('href')).offset().top - header;
        } else {
            hh = $($(this).attr('href')).offset().top;
        }
        $('html, body').animate(
            {
                scrollTop: hh,
            },
            300,
            'linear'
        );
    }); //smoothScroll
    $('.cart-btn').click(function (e) {
        e.preventDefault();
        $('#cart').addClass('open');
        $('body').addClass('cart-is-open');
    });
    $('.close-mini-cart-btn').click(function (e) {
        e.preventDefault();
        $('#cart').removeClass('open');
        $('body').removeClass('cart-is-open');
    });

    // add body top padding the header height and the height of the promotion banner
    if (document.querySelector('.discount-bar')) {
        dynamicCSS(document.querySelector('body'), document.querySelector('header'), '--header-height');
        dynamicCSS(document.querySelector('body'), document.querySelector('.discount-bar'), '--promo-height');
        dynamicCSS(document.querySelector('header'), document.querySelector('.discount-bar'), '--top');
    }
    // add search input height the header height and the height of the promotion banner
    document.querySelector('.search-input-box') &&
        document.querySelector('header') &&
        dynamicCSS(document.querySelector('.search-input-box'), document.querySelector('header'), '--header-height');

    document.querySelector('.search-input-box') &&
        document.querySelector('.discount-bar') &&
        dynamicCSS(document.querySelector('.search-input-box'), document.querySelector('.discount-bar'), '--promo-height');

    // Remove Greek Accents
    $('span, h1, h2, h3, h4, h5, h6, p, a, strong, td, button, input[type=button]').each(function () {
        $(this)
            .html()
            .replace(/[ά]/g, 'α')
            .replace(/[έ]/g, 'ε')
            .replace(/[ή]/g, 'η')
            .replace(/[ύ]/g, 'υ')
            .replace(/[ώ]/g, 'ω')
            .replace(/[ί]/g, 'ι')
            .replace(/[ό]/g, 'ο')
            .replace(/[Ά]/g, 'Α')
            .replace(/[Έ]/g, 'Ε')
            .replace(/[Ή]/g, 'Η')
            .replace(/[Υ]/g, 'Υ')
            .replace(/[Ώ]/g, 'Ω')
            .replace(/[Ί]/g, 'Ι')
            .replace(/[Ό]/g, 'Ο');
    });
    setTimeout( function(){
        $('.category-info').css('opacity', '1');
    }, 100)

    window.addEventListener('load', function () {
        $('.products-filters').css('opacity', '1');
    })
});

import 'simplebar';
import 'simplebar/dist/simplebar.css';

export { dynamicCSS };
