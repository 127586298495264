document.addEventListener('DOMContentLoaded', () => {
    const searchIcon = document.querySelector('.search-icon');
    const searchBox = document.querySelector('.search-input-box');
    const closeSearch = document.querySelector('.close-search');

    searchIcon.addEventListener('click', () => {
        searchBox.classList.toggle('active');
    });

    closeSearch.addEventListener('click', () => {
        searchBox.classList.remove('active');
    });

    // functions
    // Change location of an element on specific breakpoint
    const moveElement = (elementSelector, desktopContainerSelector, mobileContainerSelector, breakpoint) => {
        // Select the element you want to move
        const element = document.querySelector(elementSelector);
        // Select the container you want this element to be above the breakpoint
        const desktopContainer = document.querySelector(desktopContainerSelector);
        // Select the container you want this element to be bellow the breakpoint
        const mobileContainer = document.querySelector(mobileContainerSelector);

        // Initialize the element on load
        if (window.innerWidth < breakpoint && element) mobileContainer.appendChild(element);
        if (window.innerWidth >= breakpoint && element) desktopContainer.appendChild(element);

        // Add a resize listener to update the position of the element on resize
        element &&
        window.addEventListener('resize', () => {
            if (window.innerWidth < breakpoint) return mobileContainer.appendChild(element);
            if (window.innerWidth >= breakpoint) return desktopContainer.appendChild(element);
        });
    };

    // Header Sticky
    let lastScroll = 0;
    const header = document.querySelector('header');
    const promoBanner = document.querySelector('.discount-bar');

    if (window.innerWidth < 992) {
        window.addEventListener('scroll', () => {
            const scroll = window.pageYOffset;
            if (lastScroll - scroll > 0 && scroll > 200) {
                header.classList.add('sticky');
            } else {
                header.classList.remove('sticky');
            }
            if (lastScroll - scroll < 0 && scroll > 200) {
                header.classList.add('hidden-top');
                promoBanner.classList.add('hidden-top');
            } else {
                header.classList.remove('hidden-top');
                promoBanner.classList.remove('hidden-top');
            }
            lastScroll = scroll;
        });
    }

    // menu
    const subMenuTrigger = document.querySelectorAll('.submenu-1-trigger');
    const subMenuTrigger2 = document.querySelectorAll('.submenu-2-trigger');
    const subMenuTrigger3 = document.querySelectorAll('.submenu-3-trigger');
    const closeSubMenu = document.querySelector('.close-submenu');
    const closeSubmenu2 = document.querySelectorAll('.close-submenu-2');
    const submenu1 = document.querySelector('.submenu-1');
    const submenu2 = document.querySelectorAll('.submenu-2');
    // mobile menu
    const mobileSubMenuTrigger = document.querySelectorAll('.mobile-submenu-1-trigger');

    if (subMenuTrigger.length) {
        subMenuTrigger.forEach((item) => {
            item.addEventListener('click', (e) => {
                e.preventDefault();
                submenu1.classList.add('active');
                document.body.classList.add('overflow-hidden');
            });
        });
    }

    if (subMenuTrigger2.length) {
        subMenuTrigger2.forEach((item) => {
            item.addEventListener('click', (e) => {
                e.preventDefault();
                submenu2.forEach((item) => {
                    item.classList.remove('active');
                    item.style.left = `${window.innerWidth < 769 ? 0 : submenu1.offsetWidth - 2}px`;
                });
                item.nextElementSibling.classList.toggle('active');
                document.body.classList.add('overflow-hidden');
            });
        });
    }

    window.addEventListener('resize', () => {
        submenu2.forEach((item) => {
            item.style.left = `${window.innerWidth < 769 ? 0 : submenu1.offsetWidth - 2}px`;
        });
    });

    if (subMenuTrigger3.length) {
        subMenuTrigger3.forEach((item) => {
            attachExpandEvent(item);
        });
    }
    function attachExpandEvent(item) {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            submenu2.forEach((item) => {
                item.classList.remove('active');
            });
            item.nextElementSibling.classList.toggle('active');
            document.body.classList.add('overflow-hidden');
        });
    }

    if (mobileSubMenuTrigger.length) {
        mobileSubMenuTrigger.forEach((item) => {
            item.addEventListener('click', (e) => {
                e.preventDefault();
                item.parentElement.classList.toggle('active');
                item.nextElementSibling.classList.toggle('active');
                document.body.classList.add('overflow-hidden');
            });
        });
    }

    if (closeSubMenu) {
        closeSubMenu.addEventListener('click', (e) => {
            e.preventDefault();
            submenu1.classList.remove('active');
            submenu2.forEach((item) => {
                item.classList.remove('active');
            });
            document.body.classList.remove('overflow-hidden');
        });
    }

    if (closeSubmenu2) {
        closeSubmenu2.forEach((itemRemove) => {
            itemRemove.addEventListener('click', (e) => {
                e.preventDefault();
                submenu2.forEach((item) => {
                    item.classList.remove('active');
                });
            });
        });
    }

    const clearMenus = () => {
        submenu1.classList.remove('active');
        submenu2.forEach((item) => {
            item.classList.remove('active');
        });
        document.body.classList.remove('overflow-hidden');
        document.body.classList.remove('cart-is-open');
        document.querySelector('#cart').classList.remove('open');
    };

    const bodyCover = document.querySelector('.body-cover');
    bodyCover.addEventListener('click', clearMenus);

    document.addEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
            clearMenus();
        }
    });

    moveElement('.products-submenu', '.submenu', '.mobile-with-child', 992);
});
