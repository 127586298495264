window.addEventListener('DOMContentLoaded', () => {
    const productTabs = document.querySelector('.product-tabs');

    // check if product tabs exists
    if (productTabs) {
        // target all titles
        const tabHeading = document.querySelectorAll('.tab-heading');
        const tabContent = document.querySelectorAll('.tab-content');

        // loop through headings and add an event listener
        tabHeading.forEach((el) => {
            el.addEventListener('click', (e) => {
                e.preventDefault();

                // remove class from all titles
                tabHeading.forEach((el) => {
                    el.classList.remove('active');
                });

                // remove class from all tabs
                tabContent.forEach((el) => {
                    el.classList.remove('active');
                });

                // get the elements href to use it as an ID
                const href = e.currentTarget.getAttribute('href');

                // add the specific tab the active class using href
                document.querySelector(href).classList.add('active');

                // give the tab title active class
                el.classList.add('active');
            });
        });
    }
});
